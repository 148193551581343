import React, { useEffect } from "react";

export default function ReDirect() {
  // Force to send users to AI ASL
  useEffect(() => {
    window.location.href = 'https://gallaudet.edu/research/artificial-intelligence-accessibility-and-sign-language-center/';
  },[]);
 return (
  <div className = 'gally-background'>
    <div className = 'gally-container'>
      <div className = 'gally-text-container-introduction'>
        <p/>
        <main>
          <h1>
            Redirecting...
          </h1>
        </main>
      </div>
    </div>
  </div>
 );
}