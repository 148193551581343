// Set up AWS WAF for Captcha

// Three options: Get access to data about me, Delete (parts of) data about me, Correct data about me
import React, {useState, Component} from "react";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash, faWrench,faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

// ReCaptcha
import ReCaptcha from 'react-google-recaptcha';

function MainMenu(props) {
  console.log(props);
    return(
      <div className = 'gally-container flex-to-center'>
        <div className = 'options-container'>
          <div className = 'options-background-if'>
            <h1>You have a right to data access and privacy</h1>
            <p>By law, we have to give you access to your data, correct, or erase them if necessary.</p>
            <p>If you want to inquire about your data, you can contact us by email 
              at <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a>.
            </p>
            <div className = 'options-grid'>
              <button className = 'your-options options-width' onClick = {props.access} disabled={props.disabled.accessMenu}>
                <FontAwesomeIcon icon = {faEye} className = 'icon-options'/>
                Access my data
              </button>
              <button className = 'your-options options-width' onClick = {props.correct} disabled={props.disabled.correctMenu}>
                <FontAwesomeIcon icon = {faWrench} className = 'icon-options'/>
                Correct my data
              </button>
              <button className = 'your-options options-width' onClick = {props.delete} disabled={props.disabled.deleteMenu}>
                <FontAwesomeIcon icon = {faTrash} className = 'icon-options' />
                Delete my data
              </button>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    )
}

class AccessMenu extends Component {
  constructor(props){
    super(props);
    this.state = {
      userID: props.profile?.sub || null,
      email: props.profile?.email || '',
      title: 'Access data about me',
      message: 'Hello, AIASL team,\n\nI am writing to request access to my data as per my rights. I believe that you may hold personal information about me in your databases, and I would like to review this information.\n\nPlease find below the details required to process my request:\n\n1. Email Address \n2. Personal information (e.g., demographics, user ID, video recordings)\n\nIf you require any further information to locate my data, please let me know as soon as possible. Please respond to this request within the timeframe described in the Privacy Policy to ensure compliance with applicable data protection regulations. \n\nThank you for your attention to this matter. I look forward to receiving the requested information promptly.',
      empty_email: props.profile?.email === null,
      empty_title: false,
      empty_message: false,
      submit: true
    }
  };

  user = JSON.parse(localStorage.getItem('user'));
  
  if(user){
    user = user.userID
  }

  handleMessageChange = (e) => {
    const newMessage = e.target.value;
    this.setState({
      message: newMessage
    })

    if(newMessage === ''){
      this.setState({
        empty_message: true
      })
    }else{
      this.setState({
        empty_message: false
      })
    }
  }

  handleTitleChange = (e) => {
    const newTitle = e.target.value;
    this.setState({
      title: newTitle
    })
    
    if(newTitle === ''){
      this.setState({
        empty_title: true
      })
    }else{
      this.setState({
        empty_title: false
      })
    }
  }

  validateEmail = (email) => { // Solved by someone in StackOverFlow
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };
 
  handleEmailChange = (e) => {
    const newEmail = e.target.value;
    this.setState({
      email: newEmail
    })

    if(newEmail === '' | !Array.isArray(this.validateEmail(newEmail))){
      this.setState({
        empty_email: true
      })
    }else{
      this.setState({
        empty_email: false
      })
    }
  }

  handleSendSubmit = async(e) => {
    this.setState({
      submit: false
    })

    if(!Array.isArray(this.validateEmail(e.target.value))){
      e.preventDefault();
    }

    const result = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/send-email`,
      this.state,
    )

    if(result.status === 200){
      setTimeout(
        function() {
          this.setState({ submit: true });
        }.bind(this),
      2000
      )
    }
  }

  render(){

    return(
      <div id = 'email-body-container'>
        <form id = 'email-body'  onSubmit = {e => { e.preventDefault(); }}>
          <label htmlFor="email-address">Email address<span style={{display: this.state.empty_email ? 'unset' : 'none', color: 'red'}}>*</span></label>
          <input type='email' id='email-address' name='email-address' placeholder = 'Your email address' value = {this.state.email} onChange = {this.handleEmailChange}/><br/><br/>
          <label htmlFor="title">Subject<span style={{display: this.state.empty_title ? 'unset' : 'none', color: 'red'}}>*</span></label>
          <input type='text' id='title' name='title' maxLength = '78' value = {this.state.title} onChange = {this.handleTitleChange}/><br/><br/>
          <label htmlFor="message">Message<span style={{display: this.state.empty_message ? 'unset' : 'none', color: 'red'}}>*</span></label>
          <textarea id='message' name='message' maxLength = '1000' value = {this.state.message} onChange = {this.handleMessageChange}/><br/><br/>
          <button id = 'send-submit'  onClick = {this.handleSendSubmit}
           disabled = {this.state.empty_email | this.state.empty_title | this.state.empty_message | !this.state.submit | !this.props.reCap}
           aria-disabled = {this.state.empty_email | this.state.empty_title | this.state.empty_message | !this.state.submit | !this.props.reCap}
          >
            <FontAwesomeIcon icon = {faPaperPlane} id='send-icon' style = {{color: 'white'}}/> {!this.state.submit ? '...' : 'Send'}
          </button>
          <input type = 'submit' style = {{display: 'none'}} tabIndex = '0' />
        </form>
      </div>
    )
  }
}

class CorrectMenu extends Component {
  constructor(props){
    super(props);
    this.state = {
      userID: props.profile?.sub || null,
      email: props.profile?.email || '',
      title: 'Correct data about me',
      message: 'Hello, AIASL team,\n\nI am writing to request the correction of inaccurate data about me as per my rights.\n\nPlease find below the details of the incorrect information and the corrections required:\n\n1. Email Address \n2. Personal information (e.g., demographics, user ID, video recordings)\n\nI kindly request that you update your records accordingly to ensure the accuracy of my personal data. If you require any further information or documentation to process this request, please let me know promptly.\n\nAdditionally, I would appreciate it if you could confirm when the corrections have been made and provide me with a copy of the updated information. Please respond to this request within the timeframe described in the Privacy Policy to ensure compliance with applicable data protection regulations.\n\nThank you for your attention to this matter. I look forward to receiving the requested information promptly.',
      empty_email: props.profile?.email === null,
      empty_title: false,
      empty_message: false,
      submit: true
    }
  };
  user = JSON.parse(localStorage.getItem('user'));
  
  if(user){
    user = user.userID
  }

  handleMessageChange = (e) => {
    const newMessage = e.target.value;
    this.setState({
      message: newMessage
    })

    if(newMessage === ''){
      this.setState({
        empty_message: true
      })
    }else{
      this.setState({
        empty_message: false
      })
    }
  }

  handleTitleChange = (e) => {
    const newTitle = e.target.value;
    this.setState({
      title: newTitle
    })
    
    if(newTitle === ''){
      this.setState({
        empty_title: true
      })
    }else{
      this.setState({
        empty_title: false
      })
    }
  }

  validateEmail = (email) => { // Solved by someone in StackOverFlow
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };
 
  handleEmailChange = (e) => {
    const newEmail = e.target.value;
    this.setState({
      email: newEmail
    })

    if(newEmail === '' | !Array.isArray(this.validateEmail(newEmail))){
      this.setState({
        empty_email: true
      })
    }else{
      this.setState({
        empty_email: false
      })
    }
  }

  handleSendSubmit = async(e) => {
    this.setState({
      submit: false
    })

    if(!Array.isArray(this.validateEmail(e.target.value))){
      e.preventDefault();
    }

    const result = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/send-email`,
      this.state,
    )

    if(result.status === 200){
      setTimeout(
        function() {
          this.setState({ submit: true });
        }.bind(this),
      2000
      )
    }
  }

  render(){

    return(
      <div id = 'email-body-container'>
        <form id = 'email-body'  onSubmit = {e => { e.preventDefault(); }}>
          <label htmlFor="email-address">Email address<span style={{display: this.state.empty_email ? 'unset' : 'none', color: 'red'}}>*</span></label>
          <input type='email' id='email-address' name='email-address' placeholder = 'Your email address' value = {this.state.email} onChange = {this.handleEmailChange}/><br/><br/>
          <label htmlFor="title">Subject<span style={{display: this.state.empty_title ? 'unset' : 'none', color: 'red'}}>*</span></label>
          <input type='text' id='title' name='title' maxLength = '78' value = {this.state.title} onChange = {this.handleTitleChange}/><br/><br/>
          <label htmlFor="message">Message<span style={{display: this.state.empty_message ? 'unset' : 'none', color: 'red'}}>*</span></label>
          <textarea id='message' name='message' maxLength = '1000' value = {this.state.message} onChange = {this.handleMessageChange}/><br/><br/>
          <button id = 'send-submit'  onClick = {this.handleSendSubmit}
           disabled = {this.state.empty_email | this.state.empty_title | this.state.empty_message | !this.state.submit | !this.props.reCap}
           aria-disabled = {this.state.empty_email | this.state.empty_title | this.state.empty_message | !this.state.submit | !this.props.reCap}
          >
            <FontAwesomeIcon icon = {faPaperPlane} id='send-icon' style = {{color: 'white'}}/> {!this.state.submit ? '...' : 'Send'}
          </button>
          <input type = 'submit' style = {{display: 'none'}} tabIndex = '0' />
        </form>
      </div>
    )
  }
}

class DeleteMenu extends Component {
  constructor(props){
    super(props);
    this.state = {
      userID: props.profile?.sub || null,
      email: props.profile?.email || '',
      title: 'Erase data about me',
      message: 'Hello, AIASL team,\n\nI am writing to request the deletion of my personal data from your databases as per my rights. I no longer wish for my personal information to be retained by your organization.\n\nPlease find below the details of the personal data that I request to be deleted:\n\n1. Email Address \n2. Personal information (e.g., demographics, user ID, video recordings)\n\nPlease confirm the deletion of my personal data from your records and notify me once the process is completed. Additionally, I request written confirmation that my data has been permanently removed from all databases and backups within your organization. Respond to this request within the timeframe described in the Privacy Policy to ensure compliance with applicable data protection regulations.\n\nThank you for your attention to this matter. I appreciate your cooperation in ensuring the protection of my privacy rights.',
      empty_email: props.profile?.email === null,
      empty_title: false,
      empty_message: false,
      submit: true
    }
  };
  user = JSON.parse(localStorage.getItem('user'));
  
  if(user){
    user = user.userID
  }

  handleMessageChange = (e) => {
    const newMessage = e.target.value;
    this.setState({
      message: newMessage
    })

    if(newMessage === ''){
      this.setState({
        empty_message: true
      })
    }else{
      this.setState({
        empty_message: false
      })
    }
  }

  handleTitleChange = (e) => {
    const newTitle = e.target.value;
    this.setState({
      title: newTitle
    })
    
    if(newTitle === ''){
      this.setState({
        empty_title: true
      })
    }else{
      this.setState({
        empty_title: false
      })
    }
  }

  validateEmail = (email) => { // Solved by someone in StackOverFlow
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };
 
  handleEmailChange = (e) => {
    const newEmail = e.target.value;
    this.setState({
      email: newEmail
    })

    if(newEmail === '' | !Array.isArray(this.validateEmail(newEmail))){
      this.setState({
        empty_email: true
      })
    }else{
      this.setState({
        empty_email: false
      })
    }
  }

  handleSendSubmit = async(e) => {
    this.setState({
      submit: false
    })

    if(!Array.isArray(this.validateEmail(e.target.value))){
      e.preventDefault();
    }

    const result = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/send-email`,
      this.state,
    )

    if(result.status === 200){
      setTimeout(
        function() {
          this.setState({ submit: true });
        }.bind(this),
      2000
      )
    }
  }

  render(){

    return(
      <div id = 'email-body-container'>
        <form id = 'email-body'  onSubmit = {e => { e.preventDefault(); }}>
          <label htmlFor="email-address">Email address<span style={{display: this.state.empty_email ? 'unset' : 'none', color: 'red'}}>*</span></label>
          <input type='email' id='email-address' name='email-address' placeholder = 'Your email address' value = {this.state.email} onChange = {this.handleEmailChange}/><br/><br/>
          <label htmlFor="title">Subject<span style={{display: this.state.empty_title ? 'unset' : 'none', color: 'red'}}>*</span></label>
          <input type='text' id='title' name='title' maxLength = '78' value = {this.state.title} onChange = {this.handleTitleChange}/><br/><br/>
          <label htmlFor="message">Message<span style={{display: this.state.empty_message ? 'unset' : 'none', color: 'red'}}>*</span></label>
          <textarea id='message' name='message' maxLength = '1000' value = {this.state.message} onChange = {this.handleMessageChange}/><br/><br/>
          <button id = 'send-submit'  onClick = {this.handleSendSubmit}
           disabled = {this.state.empty_email | this.state.empty_title | this.state.empty_message | !this.state.submit | !this.props.reCap}
           aria-disabled = {this.state.empty_email | this.state.empty_title | this.state.empty_message | !this.state.submit | !this.props.reCap}
          >
            <FontAwesomeIcon icon = {faPaperPlane} id='send-icon' style = {{color: 'white'}}/> {!this.state.submit ? '...' : 'Send'}
          </button>
          <input type = 'submit' style = {{display: 'none'}} tabIndex = '0' />
        </form>
      </div>
    )
  }
}

export default function DataRequest({profileInfo = null}) {
  // Switch components
  const [menu, setMenu] = useState({
    mainMenu: true,
    accessMenu: false,
    correctMenu: false,
    deleteMenu: false
  })
  const openAccessMenu = () => {
    setMenu({
      mainMenu: false,
      accessMenu: true,
      correctMenu: false,
      deleteMenu: false
    })
  }
  const openCorrectMenu = () => {
    setMenu({
      mainMenu: false,
      accessMenu: false,
      correctMenu: true,
      deleteMenu: false
    })
  }
  const openDeleteMenu = () => {
    setMenu({
      mainMenu: false,
      accessMenu: false,
      correctMenu: false,
      deleteMenu: true
    })
  }
  const openMainMenu = () => {
    setMenu({
      mainMenu: true,
      accessMenu: false,
      correctMenu: false,
      deleteMenu: false
    })
  }
 
  const [reCapVerified, setReCapVerified] = useState(false);
  const reCaptchaVerify = (e) => {
    if(e !== null){
      setReCapVerified(true)
    }else{
      setReCapVerified(false)
    }
  };  

  return(
    <>
      <div className = 'gally-background'>
        <MainMenu access = {openAccessMenu} correct = {openCorrectMenu} delete = {openDeleteMenu} disabled={menu}> 
        { 
          (() => {
            if(menu['mainMenu']){
              return(
                <div id = 'email-body-container'>
                  <form id = 'email-body'  onSubmit = {e => { e.preventDefault(); }}>
                    <label htmlFor="email-address">Email address<span style={{color: 'red'}}>*</span></label>
                    <input type='email' id='email-address' name='email-address' aria-disabled={true} disabled={true}/><br/><br/>
                    <label htmlFor="title">Subject</label>
                    <input type='text' id='title' name='title' maxLength = '78' aria-disabled={true} disabled={true}/><br/><br/>
                    <label htmlFor="message">Message</label>
                    <textarea id='message' name='message' maxLength = '320' aria-disabled={true} disabled={true}/><br/><br/>
                    <button id = 'send-submit' aria-disabled={true} disabled = {true}>
                      <FontAwesomeIcon icon = {faPaperPlane} id='send-icon' style = {{color: 'white'}}/> Send
                    </button>
                    <input type = 'submit' style = {{display: 'none'}} tabIndex = '0' />
                  </form>
                </div>
              )
            }else if(menu['accessMenu']){
              return <AccessMenu main = {openMainMenu} profile = {profileInfo} reCap = {reCapVerified} />
            }else if(menu['correctMenu']){
              return <CorrectMenu main = {openMainMenu} profile = {profileInfo} reCap = {reCapVerified} />
            }else if(menu['deleteMenu']){
              return <DeleteMenu main = {openMainMenu} profile = {profileInfo} reCap = {reCapVerified}/>
            }
          })()
        }
        <ReCaptcha sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY} onChange = {reCaptchaVerify}/><br/>
        </MainMenu>
      </div>
    </>
  )
}