import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function ReDirectToDock({oktaAuth}) {
  // Force to send users to AI ASL
  const navigate = useNavigate();
  useEffect(() => {
    const handleLoginRedirect = async () => {
      if (!oktaAuth.isLoginRedirect()) {
        navigate('/dock');
        return; // Exit early if not a login redirect
      }

      try {
        // Check if we are in a login redirect state
        await new Promise(resolve => setTimeout(resolve, 500));

        if (oktaAuth.isLoginRedirect()) {
          await oktaAuth.handleLoginRedirect();
          navigate('/dock'); // Redirect to dock after successful login
          window.location.reload();
        }
      } catch (error) {
        console.error('Error handling login redirect:', error);
        navigate('/login'); // Redirect to login on error
      }
    };

    handleLoginRedirect();
}, [oktaAuth, navigate]);

 return (
  <div className = 'gally-background'>
    <div className = 'gally-container'>
      <div className = 'gally-text-container-introduction'>
        <p/>
        <main>
          <h1>
            Redocking...
          </h1>
        </main>
      </div>
    </div>
  </div>
 );
}