// Tutorial for deployment to AWS Beanstalk: https://dev.to/itsrakesh/deploying-a-mern-app-to-aws-elastic-beanstalk-with-cicd-4f1j
import './App.css';

// We use Route in order to define the different routes of our application
import React, { useState, lazy, Suspense, useCallback, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { v4 as uuidv4 } from 'uuid';

// Secure Route
import SecureRoute from './components/Secure/secure.js';

// We import all the components we need in our app
import Navbar from "./components/navbar.js";
// import Everything from './components/everything.js';
import ReDirect from './components/redirect.js';
import ReDirectToDock from './components/Secure/redirecttodock.js';
// import RecordList from "./components/recordList.js";
import DataRequest from './components/data-request.js';
// import Create from "./components/demographics.js";
import Footer from "./components/footer.js";
import ScrollToTop from './components/scrollToTop.js';
import Loading, { ErrorBoundary } from './components/loading.js';
import { Cookie, Privacy, Accessibility } from './components/policy-notices.js'; 
import LoginPage from './components/Secure/loginPage.js';

const EXPERIMENT = true;
const BATTLESHIP_GAME = false;

// Okta Auth
let isAuthenticated = false;

const oktaAuthKey = new OktaAuth({
  issuer: `${process.env.REACT_APP_OKTA_BASE_URL}/oauth2/default`,
  clientId: `${process.env.REACT_APP_OKTA_CLIENT_ID}`,
  redirectUri: `${window.location.origin}/login/callback`,
  responseType: ["code"],
  scopes: ["openid", "profile", "email"],
  pkce: true,
  cookies: { secure: false },
  idps: [
    { type: 'GOOGLE', id: process.env.REACT_APP_OKTA_GOOGLE_CLIENT_ID }
  ],
  useInteractionCodeFlow: true, // Enable embedded authentication
  transformAuthState: async (oktaAuth, authState) => {
    if (!authState.isAuthenticated) {
      const accessToken = await oktaAuth.tokenManager.get('accessToken');
      if (accessToken && !oktaAuth.tokenManager.hasExpired(accessToken)) {
        authState.isAuthenticated = true;
      }
    }
    isAuthenticated = authState.isAuthenticated; // Update global variable
    return authState;
  }
});

oktaAuthKey.start() // Trigger isAutehnticated to reflect the current authentication

// Lazy loading
const Profile = lazy(() => import('./components/Secure/profile.js'));
const WizardRoom = lazy(() => import('./components/battleship_game/wizard_room.js'));
const SingleBattle = lazy(() => import('./components/battleship_game/single-battleship.js'));
const Battleship = lazy(() => import('./components/battleship_game/battleship.js'));
const Fingerspelling = lazy(() => import('./components/battleship_game/fingerspell_only.js'))
const DockPage = lazy(() => import('./components/battleship_game/dock.js'));
const ConsentForm = lazy(() => import('./components/battleship_game/consent_form.js'));
const Video = lazy(() => import('./components/video.js'));
// const Setting = lazy(() => import('./components/setting.js'));

const SetUp = ({ oktaAuth, isAuthenticated }) => {
  // Handle redirect with login
  const navigate = useNavigate();
  const location = useLocation();
  const restoreOriginalUri = async(_oktaAuth,originalUri) => {
    navigate(toRelativeUrl(originalUri || '/dock', window.location.origin));
  };

  // Game difficulty level
  const [diffLevel,setDiffLevel] = useState('normal');
  const handleDiffLevel = (e) => {
    setDiffLevel(e);
  };

  // Get profile
  const [profileInfo, setProfileInfo] = useState({});
  const [demographic, setDemographic] = useState({});
  const [totalVideos, setTotalVideos] = useState(0);
  const loginSessionID = JSON.parse(sessionStorage.getItem('loginSession'))?.login_session_id ?? uuidv4()+'-'+Date.now();
  const [loginSession, setLoginSession] = useState({});
  const [isAdminAuth, setAdminAuth] = useState(JSON.parse(sessionStorage.getItem('isAdminAuth')));

  const getProfile = useCallback(async() => {
    // Get info
    const superUsers = process.env.REACT_APP_SUPER_USERS?.split(',') || [];

    try{
      let basic_info = null;
      if(Object.keys(profileInfo).length === 0 || ['/fingerspell','video'].includes(location.pathname)){ // Prevent from pulling data many times
        basic_info = isAuthenticated ? await oktaAuth.getUser() : null;
        const isSuperUser = superUsers.includes(basic_info?.sub);
        if(isSuperUser){
          sessionStorage.setItem('isAdminAuth',true);
          setAdminAuth(true);
        } 
      }

      if(basic_info === null){
        return;
      };

      // Get profile
      let json = {
        id: uuidv4()+'-'+Date.now(),
        sub: basic_info.sub,
        email: basic_info.email,
        family_name: basic_info.family_name,
        given_name: basic_info.given_name,
        image_base64: null,
        attack_color: null,
        attack_contrast: false,
        can_upload_video: false,
        tutorial_complete: false,
        sign_tutorial: false,
        columns: [
          'id','sub','email','family_name','given_name',
          'image_base64','attack_color',
          'attack_contrast','can_upload_video','can_upload_data',
          'tutorial_complete','sign_tutorial'
        ],
        
        wheres: {
          sub: basic_info.sub
        },
      }

      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/postgreSQL-get-profile`,
        {
          params: json,
          headers: {'Content-Type': 'application/json'}
        }
      );

      setProfileInfo({...result.data.json, inserted: result.data.inserted});

      // Get demographic
      if (!result.data?.json?.sub) {
        console.error("Missing 'sub' in result data");
        return; // Handle this case appropriately
      }
      
      json = {
        sub: basic_info.sub,
        columns: [
          'demographic_id','sub','auditory_status',
          'sign_lang','sign_age'
        ],
        wheres: {
          sub: basic_info.sub
        }
      }

      const demoInfo = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/postgreSQL-get-demographic`,
        {
          params: json,
          headers: {'Content-Type': 'application/json'}
        }
      );

      setDemographic({...demoInfo?.data.json});

      // Get total videos
      json = {
        sub: basic_info.sub
      };

      const num_videos = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/aws-total-videos`,
        {
          params: json,
          headers: {'Content-Type': 'application/json'}
        }
      );

      setTotalVideos(num_videos?.data?.result);
    }catch(err){/*Suppress error*/}
  },[isAuthenticated,oktaAuth,profileInfo,location.pathname]);

  const insertLoginSession = useCallback(async() => {
    if(Object.keys(profileInfo).length === 0){ // Prevent from pulling data many times
      return;
    }
    
    // Get info
    try{
      let json = {
        login_session_id: loginSessionID,
        login_time: new Date().toISOString().slice(0, 19).replace('T', ' '),
        logout_time: null,
        use_touch_device: localStorage.getItem('useTouchDevice') === 'Yes' ? true : false ?? null,
        device_size: JSON.parse(localStorage.getItem('deviceSize')) ?? null,
        geo_date: new Date(parseInt(localStorage.getItem('geoSetDate'),10)).toISOString().slice(0, 19).replace('T', ' ') ?? null,
        geo_location: JSON.parse(localStorage.getItem('geolocation')) ?? null,
        sub: profileInfo.sub,
        columns: [
          'login_session_id','login_time','logout_time',
          'use_touch_device','device_size',
          'geo_date','geo_location','sub'
        ],
        wheres: {
          sub: profileInfo.sub,
          login_session_id: loginSessionID
        },
      }

      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/postgreSQL-get-login_session`,
        {
          params: json,
          headers: {'Content-Type': 'application/json'}
        }
      )

      setLoginSession(
        Object.fromEntries(
          Object.entries(json).filter(([key]) => key !== 'columns')
        )
      );

      sessionStorage.setItem('loginSession',
        JSON.stringify({
          login_session_id: json.login_session_id, 
          login_time: json.login_time, 
          logout_time: null,
          wheres: {sub: profileInfo.sub, login_session_id: json.login_session_id}
        })
      )
    }catch(err){/*Suppress error*/}
  },[profileInfo, loginSessionID]);
 
  useEffect(() => {
    insertLoginSession();
  },[insertLoginSession]);

  useEffect(() => {
    getProfile();
  },[getProfile]);

  // Handle when user closes a tab or browser
  useEffect(() => {
    const handleVisibilityChange = () => {
      if(document.visibilityState === 'hidden'){
        if(Object.keys(profileInfo).length !== 0){
          const logoutTimestamp = new Date().toISOString().slice(0, 19).replace('T', ' ');
          navigator.sendBeacon(`${process.env.REACT_APP_BACKEND_URL}/postgreSQL-update-login-beacon`,
            JSON.stringify({
              updates: {
                logout_time: logoutTimestamp
              },
              wheres: {
                sub: profileInfo.sub,
                login_session_id: loginSession.login_session_id
              },
              sub: profileInfo.sub,
              login_session_id: loginSession.login_session_id,
            })
          );        
        }
      }
    }

    const handlePageHide = () => {
      // fallback for certain browsers
      if(Object.keys(profileInfo).length !== 0){
        const logoutTimestamp = new Date().toISOString().slice(0, 19).replace('T', ' ');
        navigator.sendBeacon(`${process.env.REACT_APP_BACKEND_URL}/postgreSQL-update-login`,
          JSON.stringify({
            updates: {
              logout_time: logoutTimestamp
            },
            wheres: {
              sub: profileInfo.sub,
              login_session_id: loginSession.login_session_id
            },
            sub: profileInfo.sub,
            login_session_id: loginSession.login_session_id,
          })
        );   
      }
    }

    document.addEventListener('visibilitychange',handleVisibilityChange);
    window.addEventListener('pagehide',handlePageHide);

    return () => {
      document.removeEventListener('visibilitychange',handleVisibilityChange);
      window.removeEventListener('pagehide',handlePageHide);
    }
  },[profileInfo,loginSession,oktaAuth]);

  const handleVideoUpdate = (e) => {
    setTotalVideos(e);
  };

  //console.log({can_upload_data: profileInfo?.can_upload_data});

  return(
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className="App">
        <ScrollToTop/>
        <Navbar/>
        <ErrorBoundary>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<ReDirect />} />
              {/*<Route path="/list" element={<Everything />} />
              <Route path="/aiasl-welcome" element={<RecordList />} />
              <Route path="/demographics" element={<Create />} />*/}
              <Route path="/dock" element={<DockPage getGameLevel={handleDiffLevel} experiment_model={EXPERIMENT} battleship_game={BATTLESHIP_GAME} profileInfo={profileInfo}/>} />
              <Route path='/login' element={<LoginPage />} />
              <Route path="/login/callback" element={<ReDirectToDock oktaAuth={oktaAuthKey} />} />
              <Route element={<SecureRoute />}>
                <Route path="/profile" element={<Profile profileInfo={profileInfo} demographic={demographic} totalVideos={totalVideos} updateVideoState={handleVideoUpdate}/>} />
                <Route path="/battleship" element={<Battleship />} />
                <Route path='/battle' element={<SingleBattle getGameLevel={diffLevel} experiment_mode = {EXPERIMENT} battleship_game={BATTLESHIP_GAME}/>} />
                <Route path='/fingerspell' element={<Fingerspelling experiment_mode = {EXPERIMENT} battleship_game = {BATTLESHIP_GAME} profileInfo={profileInfo} />} />
                <Route path='/video' element={<Video experiment_mode={EXPERIMENT} profileInfo={profileInfo}/>} />
                <Route path='/consent-form' element={<ConsentForm profileInfo={profileInfo} demographic={demographic} oktaAuth={oktaAuthKey}/>} />
              </Route>
              <Route path="/data-request" element={<DataRequest profileInfo={profileInfo}/>} />
              <Route path="/cookie-consent-notice" element={<Cookie />} />
              <Route path="/accessibility" element={<Accessibility />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path='/ozWizard' element={<WizardRoom experiment_mode={EXPERIMENT} isAuthenticated={isAdminAuth} />} />
              <Route path="*" element={<ReDirect />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
        <Footer/>
      </div>
    </Security>
  );
}

const App = () => {
  return(
    <Router future={{v7_startTransition: true, v7_relativeSplatPath: true}}>
      <SetUp oktaAuth={oktaAuthKey} isAuthenticated={isAuthenticated}/>
    </Router>
  )
}

export default App;
