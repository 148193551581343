import './secure_button.css';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import Google from '../assets/googleLogo.svg';
import Facebook from '../assets/facebookLogo.svg';
import React from 'react';

const LoginCallback = () => {
  const { oktaAuth } = useOktaAuth();

  const handleLogin = () => {
    oktaAuth.signInWithRedirect();
    localStorage.removeItem('skipLoginDate');
    sessionStorage.removeItem('deactivate');
  };

  return <button className = 'login' onClick={handleLogin}>Sign In With Email</button>;
};

const SignUpCallback = () => {
  const { oktaAuth } = useOktaAuth();
  const handleSignUp = () => {
    const signUpUrl = `${process.env.REACT_APP_OKTA_BASE_URL}/signin/register?client_id=${oktaAuth.options.clientId}&response_type=code&scope=openid email profile&redirect_uri=${encodeURIComponent(window.location.origin + '/callback/signup')}`;
    window.location.href = signUpUrl;
    localStorage.removeItem('skipLoginDate');
    sessionStorage.removeItem('deactivate');
  };

  return <div className='signup'><span>Need an account?</span><button onClick={handleSignUp}>Sign up</button></div>;
};

const GoogleLoginCallBack = () => {
  const { oktaAuth } = useOktaAuth();
  const handleSocialLogin = () => {
    oktaAuth.signInWithRedirect({idp: process.env.REACT_APP_OKTA_GOOGLE_CLIENT_ID});
    localStorage.removeItem('skipLoginDate');
    sessionStorage.removeItem('deactivate');
  }

  return(
    <button className = 'googleLogin' onClick={handleSocialLogin}>
      <img width='30px' src={Google} alt = "Google's letter G logo"/>
      Continue with Google
    </button>
  )
}

const FaceBookLoginCallBack = () => {
  const { oktaAuth } = useOktaAuth();
  const handleSocialLogin = () => {
    oktaAuth.signInWithRedirect({idp: process.env.REACT_APP_OKTA_FACEBOOK_CLIENT_ID});
    localStorage.removeItem('skipLoginDate');
    sessionStorage.removeItem('deactivate');
  }

  return(
    <button className = 'googleLogin' onClick={handleSocialLogin}>
      <img width='30px' src={Facebook} alt = "Facebook's FB logo"/>
      Continue with Facebook
    </button>
  )
}

const Logoff = () => {
  const { oktaAuth } = useOktaAuth();
  const handleLogOut = async () => {
    try {
      const redirectUri = window.location.origin + '/login/callback';

      await oktaAuth.closeSession();
      
      await oktaAuth.signOut({
        postLogoutRedirectUri: redirectUri,
        clearTokensBeforeRedirect: true
      });

      await oktaAuth.tokenManager.clear('okta-cache-storage');
      oktaAuth.clearAuthState();

      localStorage.removeItem('skipLoginDate');
      localStorage.removeItem('isAdminAuth');
  
      console.log('Successfully logged out');
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  };

  return(
    <button onClick={handleLogOut}>
      <div>
        <svg fill="var(--gallynavyblue)" viewBox="0 0 384.971 384.971">
          <g strokeWidth="0"></g>
          <g strokeLinecap="round" strokeLinejoin="round"></g>
          <g> 
            <g> 
              <g>
                <path d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03 C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03 C192.485,366.299,187.095,360.91,180.455,360.91z"/>
                <path d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279 c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179 c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z"/>
              </g> 
            </g> 
          </g>
        </svg>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Log out
      </div>
    </button>
  )
}

const Login = () => {
  const navigate = useNavigate();
  const handleLogIn = async() => {
    localStorage.removeItem('skipLoginDate');
    navigate('/login');
  }

  return(
    <button onClick={handleLogIn}>
      <div>
        <svg fill="var(--gallynavyblue)" viewBox="0 0 384.971 384.971">
          <g strokeWidth="0"></g>
          <g strokeLinecap="round" strokeLinejoin="round"></g>
          <g> 
            <g> 
              <g>
                <path d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03 C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03 C192.485,366.299,187.095,360.91,180.455,360.91z"/>
                <path d="M 118.519 184.088 l 83.009 -84.2 c 4.704 -4.752 12.319 -4.74 17.011 0 c 4.704 4.74 4.704 12.439 0 17.179 l -62.558 63.46 H 403.721 c 6.641 0 12.03 5.438 12.03 12.151 c 0 6.713 -5.39 12.151 -12.03 12.151 h -247.74 l 62.558 63.46 c 4.704 4.752 4.704 12.439 0 17.179 c -4.704 4.752 -12.319 4.752 -17.011 0 l -82.997 -84.2 C 113.887 196.588 113.839 188.756 118.519 184.088 z"/>
              </g> 
            </g> 
          </g>
        </svg>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Log In
      </div>
    </button>
  )
}

export { LoginCallback, SignUpCallback, GoogleLoginCallBack, FaceBookLoginCallBack, Logoff, Login };