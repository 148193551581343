import React from "react";

//import 'font-awesome/css/font-awesome.min.css';
import './loading.css';

export default function Everything() {
 return (
  <>
    <div className = 'loading-background'>
      <div className="loading-wrapping">
        <svg viewBox="25 25 50 50" className='loading-svg'>
          <circle r="20" cy="50" cx="50"></circle>
        </svg>
      </div>
    </div>
  </>
 );
}