// Tutorial for deployment to AWS Beanstalk: https://dev.to/itsrakesh/deploying-a-mern-app-to-aws-elastic-beanstalk-with-cicd-4f1j
import './App.css';

// We use Route in order to define the different routes of our application
import React, { useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

 // We import all the components we need in our app
import Navbar from "./components/navbar.js";
import Everything from './components/everything.js';
import ReDirect from './components/redirect.js';
import RecordList from "./components/recordList.js";
import DataRequest from './components/data-request.js';
import Create from "./components/demographics.js";
import Footer from "./components/footer.js";
import ScrollToTop from './components/scrollToTop.js';
import Loading from './components/loading.js';
import { Cookie, Privacy, Accessibility } from './components/policy-notices.js'; 

// Lazy loading
const SingleBattle = lazy(() => import('./components/battleship_game/single-battleship.js'));
const Battleship = lazy(() => import('./components/battleship_game/battleship.js'));
const DockPage = lazy(() => import('./components/battleship_game/dock.js'));
const Video = lazy(() => import('./components/video.js'));
const VideoForKid = lazy(() => import('./components/limited-feature-for-kids.js'));

const App = () => {
  const [diffLevel, setDiffLevel] = useState('normal');
  const handleDiffLevel = (e) => {
    setDiffLevel(e);
  }

  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Navbar/>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route exact path="/" element={<ReDirect />} />
            <Route exact path="/list" element={<Everything />} />
            <Route exact path="/aiasl-welcome" element={<RecordList />} />
            <Route exact path='/video' element={<Video />} />
            <Route exact path='/vid' element={<VideoForKid/>} />
            <Route exact path="/demographics" element={<Create />} />
            <Route exact path="/dock" element={<DockPage getGameLevel={handleDiffLevel}/>} />
            <Route exact path="/battleship" element={<Battleship />} />
            <Route exact path='/battle' element={<SingleBattle getGameLevel={diffLevel}/>} />
            <Route exact path="/data-request" element={<DataRequest />} />
            <Route exact path="/cookie-consent-notice" element={<Cookie />} />
            <Route exact path="/accessibility" element={<Accessibility />} />
            <Route exact path="/privacy-policy" element={<Privacy />} />
            <Route exact path="/*" element={<ReDirect />} />
          </Routes>
        </Suspense>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
