import React from "react";
import { Link } from "react-router-dom";
import './everything.css';

export function Cookie() {
 return (
  <>
    <div className = 'gally-background'>
      <div className = 'gally-container-white'>
        <div className = 'gally-text-container-legal'>
          <p/>
          <main>
            <h1>
              COOKIE NOTICE
            </h1>
            <p className='p-left-align'><b>Last updated April 1, 2024</b></p>
          </main>
          <p className = 'p-left-align'>
            This Cookie Policy explains how Gallaudet University ("<b>Company</b>," "<b>we</b>," "<b>us</b>," and "<b>our</b>") uses cookies and 
            similar technologies to recognize you when you visit our website 
            at <a href="https://www.gallaudetaslai.net" style = {{textDecoration: 'none'}}>https://www.gallaudetaslai.net</a> ("Website"). 
            It explains what these technologies are and why we use them, as well as your rights to control our use of them.
          </p>
          <p className = 'p-left-align'>
            In some cases we may use cookies to collect personal information, or that becomes personal information if we combine 
            it with other information.
          </p>
          <h2>
            What are cookies?
          </h2>
          <p className="p-left-align">
            Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are
            widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide
            reporting information.
          </p>
          <p className="p-left-align">
            Cookies set by the website owner (in this case, Gallaudet University) are called "first-party cookies." Cookies set by 
            parties other than the website owner are called "third-party cookies." Third-party cookies enable third-party features or 
            functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties 
            that set these third-party cookies can recognize your computer both when it visits the website in question and also when it 
            visits certain other websites.
          </p>
          <h2>
            Why do we use cookies?
          </h2>
          <p className="p-left-align">
            We use first- and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our
            Website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to 
            track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve 
            cookies through our Website for advertising, analytics, and other purposes. This is described in more detail below.
          </p>
          <h2>
            How can I control cookies
          </h2>
          <p className="p-left-align">
            You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your 
            preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies 
            you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.
          </p>
          <p className="p-left-align">
            The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, 
            you may still use our website though your access to some functionality and areas of our website may be restricted. You 
            may also set or amend your web browser controls to accept or refuse cookies.
          </p>
          <h2>
            How can I control cookies on my browser
          </h2>
          <p className="p-left-align">
            As the means by which you can refuse cookies through your web browser controls vary from browser to browser, you should 
            visit your browser's help menu for more information. The following is information about how to manage cookies on the 
            most popular browsers:
          </p>
          <ul className='p-left-align'>
            <li>
              <a href = 'https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies' 
              target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>Chrome</a>
            </li>
            <li>
              <a href = 'https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d' 
              target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>Internet Explorer</a>
            </li>
            <li>
              <a href = 'https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US' 
              target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>FireFox</a>
            </li>
            <li>
              <a href = 'https://support.apple.com/en-ie/guide/safari/sfri11471/mac' 
              target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>Safari</a>
            </li>
            <li>
              <a href = 'https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd' 
              target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>Edge</a>
            </li>
            <li>
              <a href = 'https://help.opera.com/en/latest/web-preferences/' 
              target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>Opera</a>
            </li>
          </ul>
          <p className="p-left-align">
            In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more 
            information, please visit:
          </p>
          <ul className='p-left-align'>
            <li>
              <a href = 'https://optout.aboutads.info/?c=2&lang=EN' 
              target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>Digital Advertising Alliance</a>
            </li>
            <li>
              <a href = 'https://youradchoices.ca' 
              target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>Digital Advertising Alliance of Canada</a>
            </li>
            <li>
              <a href = 'https://www.youronlinechoices.com' 
              target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>European Interactive Digital Advertising Alliance</a>
            </li>
          </ul>
          <h2>
            What about other tracking technologies, like web beacons?
          </h2>
          <p className="p-left-align">
            Cookies are not the only way to recognize or track visitors to a website. We may use 
            other, similar technologies from time to time, like web beacons (sometimes called 
            "tracking pixels" or "clear gifs"). These are tiny graphics files that contain 
            a unique identifier that enables us to recognize when someone has visited our Website 
            or opened an email including them. This allows us, for example, to monitor 
            the traffic patterns of users from one page within a website to another, to deliver 
            or communicate with cookies, to understand whether you have come to the website from 
            an online advertisement displayed on a third-party website, to improve site performance, 
            and to measure the success of email marketing campaigns. In many instances, these technologies 
            are reliant on cookies to function properly, and so declining cookies will impair their functioning.
          </p>
          <h2>Do you use Flash cookies or Local Shared Objects?</h2>
          <p className="p-left-align">
            Websites may also use so-called "Flash Cookies" (also known as Local Shared Objects or "LSOs") 
            to, among other things, collect and store information about your use of our services, fraud 
            prevention, and for other site operations.
          </p>
          <p className="p-left-align">
            If you do not want Flash Cookies stored on your computer, you can adjust the settings of 
            your Flash player to block Flash Cookies storage using the tools contained in 
            the <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html" 
            target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>Website Storage Settings Panel</a>. 
            You can also control Flash Cookies by going to 
            the <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
            target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>Global Storage Settings Panel</a> and 
            following the instructions (which may include instructions that explain, for example, how to delete existing 
            Flash Cookies (referred to "information" on the Macromedia site), how to prevent Flash LSOs from being placed 
            on your computer without your being asked, and (for Flash Player 8 and later) how to block Flash Cookies that 
            are not being delivered by the operator of the page you are on at the time).
          </p>
          <p className="p-left-align">
            Please note that setting the Flash Player to restrict or limit acceptance of Flash Cookies may reduce or 
            impede the functionality of some Flash applications, including, potentially, Flash applications used in 
            connection with our services or online content.
          </p>
          <h2>Do you serve targeted advertising?</h2>
          <p className="p-left-align">
            Third parties may serve cookies on your computer or mobile device to serve advertising through our 
            Website. These companies may use information about your visits to this and other websites in order to 
            provide relevant advertisements about goods and services that you may be interested in. They may also employ 
            technology that is used to measure the effectiveness of advertisements. They can accomplish this by using cookies 
            or web beacons to collect information about your visits to this and other sites in order to provide relevant 
            advertisements about goods and services of potential interest to you. The information collected through this 
            process does not enable us or them to identify your name, contact details, or other details that directly identify 
            you unless you choose to provide these.
          </p>
          <h2>How often will you update this Cookie Policy?</h2>
          <p className="p-left-align">
            We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies 
            we use or for other operational, legal, or regulatory reasons. Please therefore revisit this Cookie Policy 
            regularly to stay informed about our use of cookies and related technologies.
          </p>
          <p className="p-left-align">
            The date at the top of this Cookie Policy indicates when it was last updated.
          </p>
          <h2>Where can I get further information</h2>
          <p className = 'p-left-align'>
            If you have questions or comments about this notice, you may email us 
            at <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a> or contact us by post at:
          </p>
          <p className = 'p-left-align'>
            &nbsp;&nbsp;Gallaudet University<br/>
            &nbsp;&nbsp;800 Florida Ave NE<br/>
            &nbsp;&nbsp;Washington, DC 20002<br/>
            &nbsp;&nbsp;United States 
          </p>
          <p className = 'p-left-align'>
            This privacy policy was created using Termly's <a href = 'https://termly.io' style = {{textDecoration: 'none'}}>Cookie Consent Manager</a>.
          </p>
        </div>
        <Link 
          className = 'gally-left-arrow-button' 
          to={'/dock'} 
          aria-label="Gallaudet's Logo: Home page" 
          role='button'
        >
          Back
        </Link>
      </div>
    </div>
  </>
 );
}

export function Privacy() {
  return (
    <>
      <div className = 'gally-background'>
        <div className = 'gally-container-white'>
          <div className = 'gally-text-container-legal'>
            <p/>
            <main>
              <h1>
                PRIVACY NOTICE
              </h1>
              <p className='p-left-align'><b>Last updated March 10, 2025</b></p>
            </main>
            <p className = 'p-left-align'>
              This privacy notice for  Gallaudet University ("<b>we</b>","<b>us</b>", or "<b>our</b>"), describes 
              how and why we might collect, store, use, and/or share ("<b>process</b>") your information when you use 
              our services ("<b>Services</b>"), such as when you:
            </p>
            <ul className = 'p-left-align'>
              <li>
                Visit our website at <a href="https://www.gallaudetaslai.net" style = {{textDecoration: 'none'}}>https://www.gallaudetaslai.net/dock</a>, 
                or any website of ours that links to this privacy notice
              </li>
              <p/>
              <li>
                Engage with us in other related ways, including any sales, marketing, or events
              </li>
            </ul>
            <p className = 'p-left-align'>
              <b>Questions or concerns?</b> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies 
              and practices, please do not use our Services. If you still have any questions or concerns, please contact us 
              at <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a>.
            </p>
            <h2>
              SUMMARY OF KEY POINTS
            </h2>
            <p className="p-left-align">
             <b><i>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each 
              key point or by using our <a  href="#toc" style = {{textDecoration: 'none'}}>table of contents</a> below to find the section you are looking for. 
             </i></b>
            </p>
            <p className="p-left-align">
              <b>What personal information do we process?</b> When you visit, use, or navigate our Services, we may process personal information depending on how you interact 
              with us and the Services, the choices you make, and the products and features you use. Learn more about <a  href="#personal_info" style = {{textDecoration: 'none'}}>personal information you disclose to us.</a>
            </p>
            <p className="p-left-align">
              <b>Do we process any sensitive personal information?</b> We may process sensitive personal information when necessary with your consent or as otherwise permitted 
              by applicable law. Learn more about <a  href="#sensitive_info" style = {{textDecoration: 'none'}}>sensitive information we process.</a> 
            </p>
            <p className="p-left-align">
              <b>Do we receive any information from third parties?</b> We do not receive any information from third parties.
            </p>
            <p className="p-left-align">
              <b>How do we process your information?</b> We process your information to provide, improve, and administer our Services, communicate with you, 
              for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process 
              your information only when we have a valid legal reason to do so. 
              Learn more about <a  href="#process_info" style = {{textDecoration: 'none'}}>how we process your information.</a> 
            </p>
            <p className="p-left-align">
              <b>In what situations and with which parties do we share personal information?</b> We may share information in specific situations and with specific 
              third parties. Learn more about <a  href="#when_whom" style = {{textDecoration: 'none'}}>when and with whom we share your personal information.</a> 
            </p>
            <p className="p-left-align">
              <b>How do we keep your information safe?</b> We have organizational and technical processes and procedures in place to protect your personal information. 
              However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or 
              guarantee that hackers, cybercriminals, or other   unauthorized   third parties will not be able to defeat our security and improperly collect, access, steal, 
              or modify your information. 
              Learn more about <a  href="#info_safe" style = {{textDecoration: 'none'}}>how we keep your information safe.</a> 
            </p>
            <p className="p-left-align">
              <b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your 
              personal information. Learn more about <a  href="#privacy_rights" style = {{textDecoration: 'none'}}>your privacy rights.</a> 
            </p>
            <p className="p-left-align">
              <b>How do you exercise your rights?</b> The easiest way to exercise your rights is by 
              visiting <a  href="https://www.gallaudetaslai.net/data-request" style = {{textDecoration: 'none'}}>https://www.gallaudetaslai.net/data-request</a>, or 
              by contacting us. We will consider and act upon any request in accordance with applicable data protection laws. 
            </p>
            <p className="p-left-align">
              Want to learn more about what we do with any information we 
              collect? <a  href="#personal_info" style = {{textDecoration: 'none'}}>Review the privacy notice in full.</a>  
            </p>
            <h2 id = 'toc'>
              TABLE OF CONTENTS
            </h2>
            <ol className = 'p-left-align'>
              <li>
                <a  href="#personal_info" style = {{textDecoration: 'none'}}>WHAT INFORMATION DO WE COLLECT?</a>        
              </li>
              <li>
                <a  href="#process_info" style = {{textDecoration: 'none'}}>HOW DO WE PROCESS YOUR INFORMATION?</a>      
              </li>
              <li>
                <a  href="#when_whom" style = {{textDecoration: 'none'}}>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
              </li>
              <li>
                <a  href="#cookie" style = {{textDecoration: 'none'}}>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a>
              </li>
              <li>
                <a  href="#ai" style = {{textDecoration: 'none'}}>DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?</a>
              </li>
              <li>
                <a  href="#social_login" style = {{textDecoration: 'none'}}>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
              </li>
              <li>
                <a  href="#long_info" style = {{textDecoration: 'none'}}>HOW LONG DO WE KEEP YOUR INFORMATION?</a>
              </li>
              <li>
                <a  href="#info_safe" style = {{textDecoration: 'none'}}>HOW DO WE KEEP YOUR INFORMATION SAFE?</a> 
              </li>
              <li>
                <a  href="#minor" style = {{textDecoration: 'none'}}>DO WE COLLECT INFORMATION FROM MINORS?</a> 
              </li>
              <li>
                <a  href="#privacy_rights" style = {{textDecoration: 'none'}}>WHAT ARE YOUR PRIVACY RIGHTS?</a>
              </li> 
              <li>
                <a  href="#controls_not_track" style = {{textDecoration: 'none'}}>CONTROLS FOR DO-NOT-TRACK FEATURES</a>
              </li>  
              <li>
                <a  href="#specific_rights" style = {{textDecoration: 'none'}}>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
              </li>
              <li>
                <a  href="#make_updates" style = {{textDecoration: 'none'}}>DO WE MAKE UPDATES TO THIS NOTICE?</a>
              </li>
              <li>
                <a  href="#contact_notice" style = {{textDecoration: 'none'}}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
              </li>
              <li>
                <a  href="#your_control" style = {{textDecoration: 'none'}}>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? </a>
              </li>   
            </ol>
            <h2 id = 'personal_info'>
              1. WHAT INFORMATION DO WE COLLECT?
            </h2>
            <h3>Personal information you disclose to us</h3>
            <p className = 'p-left-align'><i><b>In Short:</b> We collect personal information that you provide to us.</i></p>
            <p className = 'p-left-align'>We collect personal information 
            that you voluntarily provide to us when you express an interest in obtaining information about us or our products and 
            Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
            <p className = 'p-left-align'>
              <b>Personal Information Provided by You.</b> The personal information that we collect depends on the context of your 
              interactions with us and the Services, the choices you make, and the products and features you use. The personal 
              information we collect may include the following: 
            </p>
            <ul className = 'p-left-align'>
              <li>email addresses</li>
              <li>names</li>
              <li>usernames</li>
              <li>passwords</li>
              <li>demographics</li>
              <li>locations</li>
              <li>videos</li>
              <li>profile images</li>
            </ul>
            <p className = 'p-left-align' id='sensitive_info'>
              <b>Sensitive Information.</b> When necessary, with your consent or as otherwise permitted by applicable law, we 
              process the following categories of sensitive information:
            </p>
            <ul className = 'p-left-align'>
              <li>
                information revealing race or ethnic origin
              </li>
              <li>
                information revealing auditory status and sign language ability
              </li>
            </ul>
            <p className = 'p-left-align'>
              <b>Social Media Login Data.</b> We may provide you with the option to register with us using your existing social media 
              account details, like your Google or other social media account. If you choose to register in this way, we will collect 
              certain profile information about you from the social media provider, as described in the section called 
              "<a href='#social_login'>HOW DO WE HANDLE YOUR SOCIAL LOGINS</a>" below.
            </p>
            <p className = 'p-left-align'>
              All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any 
              changes to such personal information.
            </p>
            <p className = 'p-left-align'><b>Google API</b></p>
            <p>Our use of information received from Google APIs will adhere 
              to <a href='https://developers.google.com/terms/api-services-user-data-policy' style = {{textDecoration: 'none'}} target='_blank' rel="noopener noreferrer">Google API Services User Data Policy</a>, including 
              the <a href='https://developers.google.com/terms/api-services-user-data-policy#limited-use' style = {{textDecoration: 'none'}} target='_blank' rel="noopener noreferrer">Limited Use requirements</a>
            </p>
            <h2 id = 'process_info'>
              2. HOW DO WE PROCESS YOUR INFORMATION?  
            </h2>
            <p className = 'p-left-align'><i><b>In Short:</b> We process your information to provide, improve, and administer our Services, 
            communicate with you, for security and fraud prevention, and to comply with law.</i></p>
            <p className = 'p-left-align'><b>We may also process your information for other purposes with your consent.</b></p>
            <ul className = 'p-left-align'>
              <li>
                <b>To faciliate account creation and authentication and otherwise manage user accounts.</b> We may process your information so you can create and login in to your account, as well as keep your account in working order.
              </li>
              <li>
                <b>To deliver and faciliate delivery of services to the user.</b> We may process your information to provide with the requested service.
              </li>
              <li>
                <b>To train and improve artificial intelligence.</b> The video recordings you voluntarily provide are used to train and improve artificial intelligence models for American Sign Language recognition and translation.
                These recordings may also be used to annotate additional demographic information associated with your profile. The email address you voluntarily provide is used solely for communication and you to manage account, 
                such as erasing your personal information. Please note that data already incorporated into AI training datasets or models cannot be removed due to the irreversible nature of machine learning processes. 
              </li>
            </ul>
            <h2 id = 'when_whom'>
              3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? 
            </h2>
            <p className = 'p-left-align'><i><b>In Short:</b> We may share information in specific situations described in this section and/or with the following 
            third parties.</i></p>
            <p className = 'p-left-align'>We may need to share your personal information in the following situations:</p>
            <ul className = 'p-left-align'>
              <li>
                <b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company 
                assets, financing, or acquisition of all or a portion of our business to another company.                                 
              </li>
              <p/>
              <li>
                <b>Affiliates.</b> We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. 
                Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common 
                control with us.                              
              </li>
              <p/>
              <li>
                <b>Business Partners.</b> We may share your information with our business partners to offer you certain products, services, or promotions.                             
              </li>
            </ul>
            <h2 id = 'cookie'>
              4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? 
            </h2>
            <p className = 'p-left-align'><i><b>In Short:</b> We may use cookies and other tracking technologies to collect and store your information.</i></p>
            <p className = 'p-left-align'>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. 
            Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
            </p>
            <h2 id = 'ai'>
              5. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?
            </h2>
            <p className = 'p-left-align'><i><b>In Short:</b> We offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies.</i></p>
            <p className = 'p-left-align'>
              As part of our Services, we offer products, features, or tools powered by artifical intelligence, machine learning, or similar technologies 
              (collectively, "AI Products"). These tools are designed to enhance your experience and provide you with innovative solutions. The terms in this Privacy Notice govern your use 
              of the AI Products within our Services.
            </p>
            <p className = 'p-left-align'><b>Use of AI Technologies</b></p>
            <p className = 'p-left-align'>
              We provide the AI Products through third-party service providers ("AI Service Providers"), including Google Cloud AI. As outlined in this Privacy Notice, your input, output, and personal 
              information will be shared with and processed by these AI Service Providers to enable your use of our AI Products for purposes outlined in "<a href="#when_whom" style={{textDecoration: 'none'}}>WHEN AND WITH WHOM DO WE SHARE YOUR INFORMATION?</a>"
              You must not use the AI Products in any way that violates the terms or policies of any AI Service Provider.
            </p>
            <p className = 'p-left-align'><b>Our AI Products</b></p>
            <p className = 'p-left-align'>
              Our AI Products are designed for the following functions:
              <ul className = 'p-left-align'>
                <li>Video analysis</li>
                <li>Natural language processing</li>
              </ul>
            </p>
            <p className = 'p-left-align'><b>How We Process Your Data Using AI</b></p>
            <p className = 'p-left-align'>
              All personal information processed using our AI Products is handled in line with our Privacy Notice and our agreement with third parties. This ensures high security
              and safeguards your personal information throughout the process, giving you peace of mind about your data's safety.
            </p>
            <p className = 'p-left-align'><b>How to Opt Out</b></p>
            <p className = 'p-left-align'>
              We believe in giving you the power to decide how your data is used. To opt out, you can:
            </p>
            <ul className = 'p-left-align'>
              <li>
                Log in to delete your videos and account.
              </li>
              <li>
                Update your consent with your account.
              </li>
            </ul>
            <h2 id = 'social_login'>
              6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </h2>
            <p className = 'p-left-align'><i><b>In Short:</b> If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</i></p>
            <p className = 'p-left-align'>
              Our Services offer you the ability to register and log in using your third-party social media account details (like your Google or Gmail). Where you choose to do this, we
              will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned,
              but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.
            </p>
            <p className = 'p-left-align'>
              We will use the information we receive only for the purposes that are described in this Privacy Notice or that are otherwise made clear to you on the relevant Services. Please note
              that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice 
              to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.
            </p>
            <h2 id = 'long_info'>
              7. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h2>
            <p className = 'p-left-align'><i><b>In Short:</b> We keep your personal information for as long as it is necessary to fulfill the purpose outlined in this Privacy Notice unless otherwise required by law.</i></p>
            <p className = 'p-left-align'>
              We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by 
              law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have 
              an account with us.
            </p>
            <p className = 'p-left-align'>
              When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
            </p>
            <h2 id = 'info_safe'>
              8. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </h2>
            <p className = 'p-left-align'><i><b>In Short:</b> We aim to protect your personal information through a system of   organizational   and 
            technical security measures.</i></p>
            <p className = 'p-left-align'>We have implemented appropriate and reasonable technical and   organizational   security measures designed to 
            protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no 
            electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or 
            guarantee that hackers, cybercriminals, or other   unauthorized   third parties will not be able to defeat our security and improperly collect, 
            access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information 
            to and from our Services is at your own risk. You should only access the Services within a secure environment. 
            </p>
            <h2 id = 'minor'>
              9. DO WE COLLECT INFORMATION FROM MINORS?            
            </h2>
            <p className = 'p-left-align'><i><b>In Short:</b> We do not knowingly collect data from or market to children under 18 years of age.</i></p>
            <p className = 'p-left-align'>
              We do not knowingly collect, solicit data from, or market to children under 18 years of age, not do we knowingly sell such personal information. 
              By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor
              dependent's use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate
              the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from 
              children under age 18, please contact us at aiasl@gallaudet.edu or visit the website page at https://www.gallaudetaslai.net/data-request.
            </p>
            <h2 id = 'privacy_rights'>
              10. WHAT ARE YOUR PRIVACY RIGHTS?             
            </h2>
            <p className = 'p-left-align'><i><b>In Short:</b> You may review, change, or terminate your account at any time.</i></p>
            <p className = 'p-left-align'><b><u>Withdrawing your consent:</u></b> If we are relying on your consent to process your personal information, 
            which may be express and/or implied consent depending on the applicable law,   you have the right to withdraw your consent at any time. You can 
            withdraw your consent at any time by contacting us by using the contact details provided in the section 
            "<a href = "#contact_notice" style = {{textDecoration: 'none'}}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.
            </p>
            <p className = 'p-left-align'>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, 
            when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other 
            than consent.
            </p>
            <p className = 'p-left-align'><b>Account Information</b></p>
            <p className = 'p-left-align'>
              If you would at any time like to review or change the information in your account or terminate your account, you can:
            </p>
            <ul className = 'p-left-align'>
              <li>
                Log in to your account settings and update your user account.
              </li>
              <li>
                Contact us using the contact information provided.
              </li>
            </ul>
            <p className = 'p-left-align'>
              Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases.
              However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce
              our legal terms and/or comply with applicable legal requirements.
            </p>
            <p className = 'p-left-align'><b><u>Cookies and similar technologies:</u></b> Most Web browsers are set to accept cookies by default. If 
            you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, 
            this could affect certain features or services of our Services.
            </p>
            <p className = 'p-left-align'>If you have questions or comments about your privacy rights, you may email us 
            at <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a>.
            </p>
            <h2 id = 'controls_not_track'>
              11. CONTROLS FOR DO-NOT-TRACK FEATURES            
            </h2>
            <p className = 'p-left-align'>Most web browsers and some mobile operating systems and mobile applications include a 
            Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your 
            online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing 
            and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or 
            any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online 
            tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version 
            of this privacy notice. 
            </p>
            <p className = 'p-left-align'>
              California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an 
              industry or legal standard for recognizing or honoring DNT signals, we do not respond to them at this time.
            </p>
            <h2 id = 'specific_rights'>
              12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?          
            </h2>
            <p className = 'p-left-align'><i><b>In Short:</b> If you are a resident of California, Colorado, Connecticut, Utah 
            or Virginia, you are granted specific rights regarding access to your personal information.</i></p>
            <p className = 'p-left-align'><b>What categories of personal information do we collect?</b></p>
            <p className = 'p-left-align'>We have collected the following categories of personal information in the past twelve (12) months:</p>
            <table className = 'p-left-align'>
              <thead>
                <tr>
                  <th>Cateogry</th>
                  <th>Example</th>
                  <th>Collected</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>A. Identifiers</td>
                  <td>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, 
                    online identifier, Internet Protocol address, email address, and account name
                  </td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>B. Personal information as defined in the California Customer Records statute</td>
                  <td>Name, contact information, education, employment, employment history, and financial information
                  </td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>C. Protected classification characteristics under state or federal law</td>
                  <td>Gender and date of birth
                  </td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>D. Commercial information</td>
                  <td>Transaction information, purchase history, financial details, and payment information
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>E. Biometric information</td>
                  <td>Fingerprints and voiceprints
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>F. Internet or other similar network activity</td>
                  <td>Browsing history, search history, online behavior, interest data, and interactions with 
                    our and other websites, applications, systems, and advertisements
                  </td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>G. Geolocation data</td>
                  <td>Device location
                  </td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>H. Audio, electronic, visual, thermal, olfactory, or similar information</td>
                  <td>Images and audio, video or call recordings created in connection with our business activities
                  </td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>I. Professional or employment-related information</td>
                  <td>Business contact details in order to provide you our Services at a business level or job title, 
                    work history, and professional qualifications if you apply for a job with us
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>J. Education Information</td>
                  <td>Student records and directory information
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>K. Inferences drawn from collected personal information</td>
                  <td>Inferences drawn from any of the collected personal information 
                    listed above to create a profile or summary about, for example, an 
                    individual's preferences and characteristics
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>L. Sensitive personal Information</td>
                  <td></td>
                  <td>YES</td>
                </tr>
              </tbody>
            </table>
            <p className = 'p-left-align'>We will use and retain the collected personal information as needed to provide the Services or for:</p>
            <ul className = 'p-left-align'>
              <li>
                Category A - Indefinitely                                
              </li>
              <p/>
              <li>
                Category B - Indefinitely                             
              </li>
              <p/>
              <li>
                Category C - Indefinitely                             
              </li>
              <p/>
              <li>
                Category F - Indefinitely                             
              </li>
              <p/>
              <li>
                Category G - Indefinitely                             
              </li>
              <p/>
              <li>
                Category H - Indefinitely                             
              </li>
              <li>
                Category L - Indefinitely                             
              </li>
            </ul>
            <p className = 'p-left-align'>We may also collect other personal information outside of these categories 
            through instances where you interact with us in person, online, or by phone or mail in the context of:</p>
            <ul className = 'p-left-align'>
              <li>
                Receiving help through our customer support channels;                                 
              </li>
              <p/>
              <li>
                Participation in customer surveys or contests; and                              
              </li>
              <p/>
              <li>
                Facilitation in the delivery of our Services and to respond to your inquiries.                             
              </li>
            </ul>
            <p className = 'p-left-align'><b>How do we use and share your personal information?</b></p>
            <p className = 'p-left-align'>Learn about how we use your personal information in the section 
            "<a href = '#process_info' style = {{textDecoration: 'none'}}>HOW DO WE PROCESS YOUR INFORMATION?</a></p>
            <p className = 'p-left-align'><b>Will your information be shared with anyone else?</b></p>
            <p className = 'p-left-align'>We may disclose your personal information with our service providers pursuant 
            to a written contract between us and each service provider. Learn more about how we disclose personal information 
            to in the section, "<a href = '#when_whom' style = {{textDecoration: 'none'}}>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>"
            </p>
            <p className = 'p-left-align'>We may use your personal information for our own business purposes, such as for undertaking 
            internal research for technological development and demonstration. This is not considered to be "selling" of your personal 
            information.
            </p>
            <p className = 'p-left-align'>We have not disclosed, sold, or shared any personal information to third parties for a 
            business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal 
            information in the future belonging to website visitors, users, and other consumers.
            </p>
            <h3>California Residents</h3>
            <p className = 'p-left-align'>California Civil Code Section 1798.83, also known as the "Shine The Light" law permits 
            our users who are California residents to request and obtain from us, once a year and free of charge, information 
            about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and 
            the names and addresses of all third parties with which we shared personal information in the immediately preceding 
            calendar year. If you are a California resident and would like to make such a request, please submit your request in 
            writing to us using the contact information provided below.
            </p>
            <p className = 'p-left-align'>If you are under 18 years of age, reside in California, and have a registered account 
            with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. 
            To request removal of such data, please contact us using the contact information provided below and include the email 
            address associated with your account and a statement that you reside in California. We will make sure the data is not 
            publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed 
            from all our systems (e.g., backups, etc.).
            </p>
            <p className = 'p-left-align'><u><b>CCPA Privacy Notice</b></u></p>
            <p className = 'p-left-align'>This section applies only to California residents. Under the California Consumer Privacy Act (CCPA), you have the rights listed below.
            </p>
            <p className="p-left-align">The California Code of Regulations defines a "residents" as:</p>
            <ul className="p-left-align" style = {{listStyle: 'none'}}>
              <li>
                (1) every individual who is in the State of California for other than a temporary or transitory purpose and
              </li>
              <p/>
              <li>
                (2) every individual who is domiciled in the State of California who is outside the State of California for a 
                temporary or transitory purpose
              </li>
            </ul>
            <p className="p-left-align">
              All other individuals are defined as "non-residents."
            </p>
            <p className="p-left-align">
              If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your 
              personal information.
            </p>
            <p className="p-left-align"><b>Your rights with respect to your personal data</b></p>
            <p className="p-left-align"><u>Right to request deletion of the data - Request to delete</u></p>
            <p className="p-left-align">
              You can ask for the deletion of your personal information. If you ask us to delete your personal information, we 
              will respect your request and delete your personal information, subject to certain exceptions provided by law, such 
              as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements 
              resulting from a legal obligation, or any processing that may be required to protect against illegal activities.
            </p>
            <p className="p-left-align"><u>Right to be informed - Request to know</u></p>
            <p className="p-left-align">
              Depending on the circumstances, you have a right to know: 
            </p>
            <ul className="p-left-align">
              <li>
                whether we collect and use your personal information; 
              </li>
              <p/>
              <li>
                the categories of personal information that we collect;
              </li>
              <p/>
              <li>
                the purposes for which the collected personal information is used;
              </li>
              <p/>
              <li>
                whether we sell or share personal information to third parties;
              </li>
              <p/>
              <li>
                the categories of personal information that we sold, shared, or disclosed for a business purpose;
              </li>
              <p/>
              <li>
                the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;
              </li>
              <p/>
              <li>
                the business or commercial purpose for collecting, selling, or sharing personal information; and
              </li>
              <p/>
              <li>
                the specific pieces of personal information we collected about you.
              </li>
            </ul>
            <p className="p-left-align">
              In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified 
              in response to a consumer request or to re-identify individual data to verify a consumer request.
            </p>
            <p className="p-left-align">
              <u>Right to Non-Discrimination for the Exercise of a Consumer's Privacy Rights</u>
            </p>
            <p className="p-left-align">
              We will not discriminate against you if you exercise your privacy rights.
            </p>
            <p className="p-left-align">
              <u>Right to Limit Use and Disclosure of Sensitive Personal Information</u>
            </p>
            <p className="p-left-align">
              We do not process consumer's sensitive personal information.
            </p>
            <p className="p-left-align">
              <u>Verification process</u>
            </p>
            <p className="p-left-align">
              Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the 
              information in our system. These verification efforts require us to ask you to provide information so that we can match it with 
              information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide 
              certain information so that we can match the information you provide with the information we already have on file, or we may contact 
              you through a communication method (e.g.  ,   phone or email) that you have previously provided to us. We may also use other 
              verification methods as the circumstances dictate.            
            </p>
            <p className="p-left-align">We will only use personal information provided in your request to verify your identity or authority to 
            make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. 
            However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional 
            information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally 
            provided information as soon as we finish verifying you. 
            </p>
            <p className="p-left-align">
              <u>Other privacy rights</u>
            </p>
            <ul className="p-left-align">
              <li>
                You may object to the processing of your personal information.   
              </li>
              <p/>
              <li>
                You may request correction of your personal data if it is incorrect or no 
                longer relevant, or ask to restrict the processing of the information.
              </li>
              <p/>
              <li>
                You can designate an authorized agent to make a request under the CCPA on your behalf. 
                We may deny a request from an authorized agent that does not submit proof that they have been 
                validly authorized to act on your behalf in accordance with the CCPA.  
              </li>
              <p/>
              <li>
                You may request to opt out from future selling or sharing of your personal information to third parties. 
                Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no 
                later than fifteen (15) days from the date of the request submission.
              </li>
              <p/>
              <li>
                We will honor your opt-out preferences if you enact 
                the <a href = 'https://globalprivacycontrol.org'
                target="_blank" rel="noopener noreferrer" 
                style = {{textDecoration: 'none'}}>Global Privacy Control</a> (GPC) opt-out signal on 
                your browser.
              </li>
            </ul>
            <p className="p-left-align">
              To exercise these rights, you can contact us by 
              visiting <a href = 'https://www.gallaudetaslai.net/data-request' style = {{textDecoration: 'none'}}>https://www.gallaudetaslai.net/data-request</a>,
              by email at <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a>, 
              or by referring to the contact details at the bottom of this document. If 
              you have a complaint about how we handle your data, we would like to hear from you.
            </p>
            <h3>Colorado Residents</h3>
            <p className="p-left-align">
              This section applies only to Colorado residents. Under the Colorado Privacy Act (CPA), you have the rights listed 
              below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by 
              law.
            </p>
            <ul className="p-left-align">
              <li>
                Right to be informed whether or not we are processing your personal data
              </li>
              <p/>
              <li>
                Right to access your personal data 
              </li>
              <p/>
              <li>
                Right to correct inaccuracies in your personal data  
              </li>
              <p/>
              <li>
                Right to request deletion of your personal data
              </li>
              <p/>
              <li>
                Right to obtain a copy of the personal data you previously shared with us 
              </li>
              <p/>
              <li>
                Right to opt out of the processing of your personal data if it is used for targeted 
                advertising, the sale of personal data, or profiling in furtherance of decisions that 
                produce legal or similarly significant effects ("profiling") 
              </li>
            </ul>
            <p className="p-left-align">
              To submit a request to exercise these rights described above, please 
              email <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a> or 
              visit <a href = "https://www.gallaudetaslai.net/data-request" style = {{textDecoration: 'none'}}>https://www.gallaudetaslai.net/data-request</a>.
            </p>
            <p className="p-left-align">
              If we decline to take action regarding your request and you wish to appeal our decision, please email us 
              at <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a>. 
              Within forty-five (45) days of receipt of an appeal, we will inform you in writing of any action taken or 
              not taken in response to the appeal, including a written explanation of the reasons for the decisions.
            </p>
            <h3>Connecticut Residents</h3>
            <p className="p-left-align">
              This section applies only to Connecticut residents. Under the Connecticut Data Privacy Act (CTDPA), you 
              have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline 
              your request as permitted by law.
            </p>
            <ul className="p-left-align">
              <li>
                Right to be informed whether or not we are processing your personal data
              </li>
              <p/>
              <li>
                Right to access your personal data 
              </li>
              <p/>
              <li>
                Right to correct inaccuracies in your personal data  
              </li>
              <p/>
              <li>
                Right to request deletion of your personal data
              </li>
              <p/>
              <li>
                Right to obtain a copy of the personal data you previously shared with us 
              </li>
              <p/>
              <li>
                Right to opt out of the processing of your personal data if it is used for targeted 
                advertising, the sale of personal data, or profiling in furtherance of decisions that 
                produce legal or similarly significant effects ("profiling") 
              </li>
            </ul>
            <p className="p-left-align">
              To submit a request to exercise these rights described above, please 
              email <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a> or 
              visit <a href = "https://www.gallaudetaslai.net/data-request" style = {{textDecoration: 'none'}}>https://www.gallaudetaslai.net/data-request</a>.
            </p>
            <p className="p-left-align">
              If we decline to take action regarding your request and you wish to appeal our decision, please email us 
              at <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a>. 
              Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or 
              not taken in response to the appeal, including a written explanation of the reasons for the decisions.
            </p>
            <h3>Utah Residents</h3>
            <p className="p-left-align">
              This section applies only to Utah residents. Under the Utah Consumer Privacy Act (UCPA), you have the rights 
              listed below. However, these rights are not absolute, and in certain cases, we may decline your request as 
              permitted by law. 
            </p>
            <ul className="p-left-align">
              <li>
                Right to be informed whether or not we are processing your personal data
              </li>
              <p/>
              <li>
                Right to access your personal data 
              </li>
              <p/>
              <p/>
              <li>
                Right to request deletion of your personal data
              </li>
              <p/>
              <li>
                Right to obtain a copy of the personal data you previously shared with us 
              </li>
              <p/>
              <li>
                Right to opt out of the processing of your personal data if it is used for 
                targeted advertising or the sale of personal data
              </li>
            </ul>
            <p className="p-left-align">
              To submit a request to exercise these rights described above, please 
              email <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a> or 
              visit <a href = "https://www.gallaudetaslai.net/data-request" style = {{textDecoration: 'none'}}>https://www.gallaudetaslai.net/data-request</a>.
            </p>
            <h3>Virginia Residents</h3>
            <p className="p-left-align">
              Under the Virginia Consumer Data Protection Act (VCDPA): 
            </p>
            <p className="p-left-align">
              "Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. 
              It does not include a natural person acting in a commercial or employment context.
            </p>
            <p className="p-left-align">
              "Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. 
              "Personal data" does not include de-identified data or publicly available information.  
            </p>
            <p className="p-left-align">
              "Sale of personal data" means the exchange of personal data for monetary consideration.   
            </p>
            <p className="p-left-align">
              If this definition of "consumer" applies to you, we must adhere to certain rights and 
              obligations regarding your personal data.
            </p>
            <p className="p-left-align">
              <u>Your rights with respect to your personal data</u>
            </p>
            <ul className="p-left-align">
              <li>
                Right to be informed whether or not we are processing your personal data
              </li>
              <p/>
              <li>
                Right to access your personal data 
              </li>
              <p/>
              <li>
                Right to correct inaccuracies in your personal data  
              </li>
              <p/>
              <li>
                Right to request deletion of your personal data
              </li>
              <p/>
              <li>
                Right to obtain a copy of the personal data you previously shared with us 
              </li>
              <p/>
              <li>
                Right to opt out of the processing of your personal data if it is used for targeted 
                advertising, the sale of personal data, or profiling in furtherance of decisions that 
                produce legal or similarly significant effects ("profiling") 
              </li>
            </ul>
            <p className="p-left-align">
              <u>Exercise your rights provided under the Virginia VCDPA</u>
            </p>
            <p className="p-left-align">
              You may contact us by email 
              at <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a> visit <a href = 'https://www.gallaudetaslai.net/data-request' style = {{textDecoration: 'none'}}>https://www.gallaudetaslai.net/data-request</a>.
            </p>
            <p className="p-left-align">
              If you are using an authorized agent to exercise your rights, we may deny a request if the 
              authorized agent does not submit proof that they have been validly authorized to act on 
              your behalf.
            </p>
            <p className="p-left-align">
              <u>Verification process</u>
            </p>
            <p className="p-left-align">
              We may request that you provide additional information reasonably necessary to verify you and your consumer's request. 
              If you submit the request through an authorized agent, we may need to collect additional information to verify your 
              identity before processing your request.            
            </p>
            <p className="p-left-align">
              Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The 
              response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such 
              extension within the initial 45-day response period, together with the reason for the extension. 
            </p>
            <p className="p-left-align">
              <u>Right to appeal</u>
            </p>
            <p className="p-left-align">
              If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to 
              appeal our decision, please email us 
              at <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a>. Within sixty 
              (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, 
              including a written explanation of the reasons for the decisions. If your appeal is denied, you may contact 
              the <a href = 'https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint'
                target="_blank" rel="noopener noreferrer" 
                style = {{textDecoration: 'none'}}>Attorney General</a> to submit a complaint.            
            </p>
            <h2 id = 'make_updates'>
              13. DO WE MAKE UPDATES TO THIS NOTICE?
            </h2>
            <p className = 'p-left-align'><i><b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.</i></p>
            <p className = 'p-left-align'>
              We may update this privacy notice from time to time. The updated version will be indicated by an updated 
              "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy 
              notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We 
              encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
            </p>
            <h2 id = 'contact_notice'>
              14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </h2>
            <p className = 'p-left-align'>
              If you have questions or comments about this notice, you may email us 
              at <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a> or contact us by post at:
            </p>
            <p className = 'p-left-align'>
              &nbsp;&nbsp;Gallaudet University<br/>
              &nbsp;&nbsp;800 Florida Ave NE<br/>
              &nbsp;&nbsp;Washington, DC 20002<br/>
              &nbsp;&nbsp;United States 
            </p>
            <h2 id = 'your_control'>
              15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
            </h2>
            <p className = 'p-left-align'>
              You have the right to request access to the personal information we collect from you, change that information, or delete it. 
              To request to review, update, or delete your personal information, please 
              visit: <a href="https://www.gallaudetaslai.net/data-request" style = {{textDecoration: 'none'}}>https://www.gallaudetaslai.net/data-request</a> or <a href="https://www.gallaudetaslai.net/profile" style = {{textDecoration: 'none'}}>https://www.gallaudetaslai.net/profile</a>
            </p>
            <p className = 'p-left-align'>
              This privacy policy was created using Termly's <a href = 'https://termly.io' style = {{textDecoration: 'none'}}>Privacy Policy Generator</a>
            </p>
          </div>
          <Link 
            className = 'gally-left-arrow-button' 
            to={'/dock'} 
            aria-label="Gallaudet's Logo: Home page" 
            role='button'
          >
            Back
          </Link>
        </div>
      </div>
    </>
  );
}

export function Accessibility() {
  return (
   <>
    <div className = 'gally-background'>
      <div className = 'gally-container-white'>
        <div className = 'gally-text-container-legal'>
          <p/>
          <main>
            <h1>
              ACCESSIBILITY DISCLAIMER
            </h1>
            <p className='p-left-align'><b>Last updated April 1, 2024</b></p>
          </main>
          <p className = 'p-left-align'>
            Gallaudet University ("<b>Company</b>," "<b>we</b>," "<b>us</b>," and "<b>our</b>") is committed to 
            providing a <a href="https://www.gallaudetaslai.net" style = {{textDecoration: 'none'}}>https://www.gallaudetaslai.net</a> ("Website")
            accessible to the broadest possible audience of signed language users. 
            The accessibility disclaimer describes what kind 
            of <a href="#disclaimer" style = {{textDecoration: 'none'}}>accessibility limitations</a> we 
            currently have to the best of our knowledge and ability, and we continue to work hard to improve our site, 
            ensuring that it is accessible to all users regardless of ability or method of access.   
          </p>
          <h2>Ongoing Efforts to Ensure Accessibility</h2>
          <p className = 'p-left-align'>
            We strive to have our site compliant 
            by <a href = 'https://www.w3.org/TR/WCAG21/' style = {{textDecoration: 'none'}}>Web Content Accessibility (WCAG) 2.1 AAA Success Criteria</a>,
            the highest and most optimal accessibility level. The WCAG guidelines explain how 
            to make our site more accessible to people with disabilities, and 
            user-friendly for everyone. The guidelines have three levels of 
            accessibility (A, AA and AAA). Our site is currently at WCAG 2.1 AA level.
          </p>
          <h2 id = 'disclaimer'>What kind of accessibility limitations do we have?</h2>
          <p className = 'p-left-align'><i><b>In Short:</b> Our site is currently not accessible friendly to blind users.</i></p>
          <p className = 'p-left-align'>
            Our video recording page incorporates artificial technology, specifically hand pose detection, to analyze the 
            positioning of users' hands in real time within continuous video. This technology utilizes skeletal landmark points 
            to describe the hand's posture at any given moment. This page includes a set of instructional contents for users, 
            some of which must be conveyed in American Sign Language (ASL). Variations exist in signing the word 'Back' 
            in ASL, and due to constraints in our dataset, users may need to adhere to specific signing conventions. 
          </p>
          <p className="p-left-align">
            This information cannot be accurately translated into English. We are dedicated to ensuring accessibility for 
            all users and appreciate your understanding as we work to improve our accommodations.
          </p>
          <p className = 'p-left-align'><b>Questions or concerns?</b> You can contact us by email 
          at <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a>, 
          or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle 
          accommodations or encounter any other problems, we would like to hear from you.
          </p>
          <h2>Receiving Additional Help</h2>
          <p className = 'p-left-align'>
            Most popular browsers and plugins contain built-in accessibility
            tools and features. Please visit this information pages for these tools.
          </p>
          <ul className='p-left-align'>
            <li>
              <a href = 'https://about.google/belonging/disability-inclusion/product-accessibility/' 
              target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>Google Accessibility for Chrome Browser</a>
            </li>
            <li>
              <a href = 'https://www.microsoft.com/en-us/accessibility/' 
              target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>Microsoft Accessibility for Internet Explorer Browser</a>
            </li>
            <li>
              <a href = 'http://www.accessfirefox.com/' 
              target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>Accessibility Information for the FireFox Browser</a>
            </li>
          </ul>
          <p className="p-left-align">If you would like to learn more about the web accessibility guidelines, visit 
            this <a href = 'https://www.w3.org/WAI/standards-guidelines/wcag/' 
              target='_blank' rel='noopener noreferrer' style={{textDecoration:'none'}}>website</a>.</p>
          <h2>How can you contact us about this accessibility disclaimer?</h2>
          <div className="p-left-align">
            <p className = 'p-left-align'>
              If you have questions or comments about this disclaimer, you may email us 
              at <a href = "mailto: aiasl@gallaudet.edu" style = {{textDecoration: 'none'}}>aiasl@gallaudet.edu</a> or contact us by post at:
            </p>
            <p className = 'p-left-align'>
              &nbsp;&nbsp;Gallaudet University<br/>
              &nbsp;&nbsp;800 Florida Ave NE<br/>
              &nbsp;&nbsp;Washington, DC 20002<br/>
              &nbsp;&nbsp;United States 
            </p>
          </div>
        </div>
        <Link 
          className = 'gally-left-arrow-button' 
          to={'/dock'} 
          aria-label="Gallaudet's Logo: Home page" 
          role='button'
        >
          Back
        </Link>
      </div>
     </div>
   </>
  );
}
