import React from "react";
import { Link } from "react-router-dom";

//import 'font-awesome/css/font-awesome.min.css';
import './everything.css';

export default function Everything() {
 return (
  <>
    <div className = 'gally-background'>
      <div className = 'gally-container'>
        <div className = 'gally-text-container-introduction'>
          <p/>
          <main>
            <h1>
              Everything You Can Find
            </h1>
          </main>
          <p/>
          <Link className = 'your-options' to={'/aiasl-welcome'} tabIndex = '0'>
            Video Recording
          </Link>
          <Link className = 'your-options' to={'/dock'} tabIndex = '0'>
            Battleship
          </Link>
        </div>
      </div>
    </div>
  </>
 );
}