// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-background {
    display: flex;  
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: var(--whitebackground);
    z-index: 0;
}

.loading-wrapping{
    height: 300px;
    width: 300px;
}

.loading-svg {
 width: 300px;
 transform-origin: center;
 animation: rotate4 1.5s linear infinite;
}

.loading-svg circle {
 fill: none;
 stroke: var(--gallyblue);
 stroke-width: 2;
 stroke-dasharray: 1, 200;
 stroke-dashoffset: 0;
 stroke-linecap: round;
 animation: dash4 2s ease-in-out infinite;
}

@keyframes rotate4 {
 100% {
  transform: rotate(360deg);
 }
}

@keyframes dash4 {
 0% {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
 }

 50% {
  stroke-dasharray: 90, 200;
  stroke-dashoffset: -35px;
 }

 100% {
  stroke-dashoffset: -125px;
 }
}`, "",{"version":3,"sources":["webpack://./src/components/loading.css"],"names":[],"mappings":"AAAA;IAKI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,kCAAkC;IAClC,UAAU;AACd;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;CACC,YAAY;CACZ,wBAAwB;CACxB,uCAAuC;AACxC;;AAEA;CACC,UAAU;CACV,wBAAwB;CACxB,eAAe;CACf,wBAAwB;CACxB,oBAAoB;CACpB,qBAAqB;CACrB,wCAAwC;AACzC;;AAEA;CACC;EACC,yBAAyB;CAC1B;AACD;;AAEA;CACC;EACC,wBAAwB;EACxB,oBAAoB;CACrB;;CAEA;EACC,yBAAyB;EACzB,wBAAwB;CACzB;;CAEA;EACC,yBAAyB;CAC1B;AACD","sourcesContent":[".loading-background {\n    display: -webkit-box;\n    display: -moz-box;\n    display: -ms-flexbox;\n    display: -webkit-flex;\n    display: flex;  \n    justify-content: center;\n    align-items: center;\n    min-height: 100vh;\n    background: var(--whitebackground);\n    z-index: 0;\n}\n\n.loading-wrapping{\n    height: 300px;\n    width: 300px;\n}\n\n.loading-svg {\n width: 300px;\n transform-origin: center;\n animation: rotate4 1.5s linear infinite;\n}\n\n.loading-svg circle {\n fill: none;\n stroke: var(--gallyblue);\n stroke-width: 2;\n stroke-dasharray: 1, 200;\n stroke-dashoffset: 0;\n stroke-linecap: round;\n animation: dash4 2s ease-in-out infinite;\n}\n\n@keyframes rotate4 {\n 100% {\n  transform: rotate(360deg);\n }\n}\n\n@keyframes dash4 {\n 0% {\n  stroke-dasharray: 1, 200;\n  stroke-dashoffset: 0;\n }\n\n 50% {\n  stroke-dasharray: 90, 200;\n  stroke-dashoffset: -35px;\n }\n\n 100% {\n  stroke-dashoffset: -125px;\n }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
